import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_action_table_column = _resolveComponent("action-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_confirm_modal = _resolveComponent("confirm-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_table, {
      colspan: "3",
      loading: _ctx.loading,
      hasMore: _ctx.hasMore,
      onLoadMore: _ctx.loadMore,
      noDataAvailable: _ctx.noDataAvailable,
      noResultFound: _ctx.noResultFound,
      hover: ""
    }, {
      header: _withCtx(() => [
        _createVNode(_component_base_table_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.fleet.ship_name")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, {
              type: "label",
              "text-align": "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.fleet.crew_on_board")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_action_table_column, { type: "label" })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
          return (_openBlock(), _createBlock(_component_base_table_row, {
            key: row.id,
            onClick: _withModifiers(($event: any) => (
          _ctx.useRouter().push({
            name: _ctx.RouteNames.FleetDetail,
            params: {
              id: row.id
            }
          })
        ), ["stop"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.ship_name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { "text-align": "center" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.crew_boards_count), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_action_table_column, {
                onDelete: ($event: any) => (_ctx.showConfirmDelete(row)),
                "edit-route": { name: _ctx.RouteNames.FleetEdit, params: { id: row.id } }
              }, null, 8, ["onDelete", "edit-route"])
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["loading", "hasMore", "onLoadMore", "noDataAvailable", "noResultFound"]),
    _createVNode(_component_confirm_modal, {
      type: "delete",
      title: _ctx.trans('layout.modal_title.confirm_delete_fleet'),
      modelValue: _ctx.confirmDeleteModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.confirmDeleteModal) = $event)),
      onYes: _ctx.deleteSelectedRow
    }, {
      default: _withCtx(() => [
        (_ctx.selectedRow)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.trans('layout.fleet.confirm_delete', {shipName: _ctx.selectedRow.ship_name})), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "modelValue", "onYes"])
  ], 64))
}